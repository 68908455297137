import { useMemo } from 'react';

interface Props {
  isClosingTour?: boolean;
  isConfiguringVehicleProducts?: boolean;
  isCreatingSolution?: boolean;
  isDeletingCandidates?: boolean;
  isDeletingChargingEvents?: boolean;
  isDeletingDepot?: boolean;
  isDeletingMapLocation?: boolean;
  isDeletingSolutions?: boolean;
  isDuplicatingCandidates?: boolean;
  isMappingVehicles?: boolean;
  isLoadingCandidate?: boolean;
  isLoadingCandidates?: boolean;
  isLoadingDepot?: boolean;
  isLoadingDepots?: boolean;
  isLoadingEnvironmentalImpact?: boolean;
  isLoadingHomeBases?: boolean;
  isLoadingPtoDevices?: boolean;
  isLoadingRoutes?: boolean;
  isLoadingSolution?: boolean;
  isLoadingSolutions?: boolean;
  isLoadingStops?: boolean;
  isLoadingTrips?: boolean;
  isLoadingChargingPoints?: boolean;
  isLoadingUser?: boolean;
  isSaving?: boolean;
  isSavingCandidate?: boolean;
  isSavingDepot?: boolean;
  isSavingEvaluation?: boolean;
  isCalculatingEvaluation?: boolean;
  isSavingInfrastructure?: boolean;
  isSavingMapLocation?: boolean;
  isSavingPto?: boolean;
  isSavingWaypoints?: boolean;
  isUpdatingChargingEvent?: boolean;
  isUpdatingOperationFactors?: boolean;
  isUpdatingVehicleConfiguration?: boolean;
  isValidatingChargingEvent?: boolean;
  isValidatingInfraPerformanceStep?: boolean;
}

const useLoadingText = ({
  isClosingTour,
  isConfiguringVehicleProducts,
  isCreatingSolution,
  isDeletingCandidates,
  isDeletingChargingEvents,
  isDeletingDepot,
  isDeletingMapLocation,
  isDeletingSolutions,
  isDuplicatingCandidates,
  isMappingVehicles,
  isLoadingCandidate,
  isLoadingCandidates,
  isLoadingDepot,
  isLoadingDepots,
  isLoadingEnvironmentalImpact,
  isLoadingHomeBases,
  isLoadingPtoDevices,
  isLoadingRoutes,
  isLoadingSolution,
  isLoadingSolutions,
  isLoadingStops,
  isLoadingTrips,
  isLoadingChargingPoints,
  isLoadingUser,
  isSaving,
  isSavingCandidate,
  isSavingDepot,
  isSavingEvaluation,
  isCalculatingEvaluation,
  isSavingInfrastructure,
  isSavingMapLocation,
  isSavingPto,
  isSavingWaypoints,
  isUpdatingChargingEvent,
  isUpdatingOperationFactors,
  isUpdatingVehicleConfiguration,
  isValidatingChargingEvent,
  isValidatingInfraPerformanceStep,
}: Props): string | undefined =>
  useMemo(() => {
    if (isClosingTour) {
      return 'Closing tour';
    } else if (isConfiguringVehicleProducts) {
      return 'Configuring vehicle products';
    } else if (isCreatingSolution) {
      return 'Creating solution';
    } else if (isDeletingCandidates) {
      return 'Deleting candidates';
    } else if (isDeletingChargingEvents) {
      return 'Deleting charging events';
    } else if (isDeletingMapLocation) {
      return 'Deleting location';
    } else if (isDeletingDepot) {
      return 'Deleting depot';
    } else if (isDeletingSolutions) {
      return 'Deleting solutions';
    } else if (isDuplicatingCandidates) {
      return 'Duplicating candidates';
    } else if (isMappingVehicles) {
      return 'Mapping vehicles';
    } else if (isLoadingCandidate) {
      return 'Loading candidate';
    } else if (isLoadingCandidates) {
      return 'Loading candidates';
    } else if (isLoadingDepot) {
      return 'Loading depot';
    } else if (isLoadingDepots) {
      return 'Loading depots';
    } else if (isLoadingEnvironmentalImpact) {
      return 'Loading environmental impact';
    } else if (isLoadingHomeBases) {
      return 'Loading depot suggestions';
    } else if (isLoadingPtoDevices) {
      return 'Loading PTO';
    } else if (isLoadingRoutes) {
      return 'Loading routes';
    } else if (isLoadingSolution) {
      return 'Loading solution';
    } else if (isLoadingSolutions) {
      return 'Loading solutions';
    } else if (isLoadingStops) {
      return 'Loading stops';
    } else if (isLoadingTrips) {
      return 'Loading trips';
    } else if (isLoadingChargingPoints) {
      return 'Loading charging points';
    } else if (isLoadingUser) {
      return 'Loading user';
    } else if (isSaving) {
      return 'Saving';
    } else if (isSavingCandidate) {
      return 'Saving candidate';
    } else if (isSavingDepot) {
      return 'Saving depot';
    } else if (isSavingEvaluation) {
      return 'Saving evaluation';
    } else if (isCalculatingEvaluation) {
      return 'Calculating new total';
    } else if (isSavingInfrastructure) {
      return 'Saving infrastructure';
    } else if (isSavingMapLocation) {
      return 'Saving location';
    } else if (isSavingPto) {
      return 'Saving PTO';
    } else if (isSavingWaypoints) {
      return 'Saving waypoints';
    } else if (isUpdatingChargingEvent) {
      return 'Updating charging events';
    } else if (isUpdatingOperationFactors) {
      return 'Saving operational factors';
    } else if (isUpdatingVehicleConfiguration) {
      return 'Saving vehicle configuration';
    } else if (isValidatingChargingEvent) {
      return 'Validating charging event';
    } else if (isValidatingInfraPerformanceStep) {
      return 'Validating infrastructure';
    }
  }, [
    isClosingTour,
    isConfiguringVehicleProducts,
    isCreatingSolution,
    isDeletingCandidates,
    isDeletingChargingEvents,
    isDeletingMapLocation,
    isDeletingDepot,
    isDeletingSolutions,
    isDuplicatingCandidates,
    isMappingVehicles,
    isLoadingCandidate,
    isLoadingCandidates,
    isLoadingDepot,
    isLoadingDepots,
    isLoadingEnvironmentalImpact,
    isLoadingHomeBases,
    isLoadingPtoDevices,
    isLoadingSolution,
    isLoadingSolutions,
    isLoadingStops,
    isLoadingTrips,
    isLoadingChargingPoints,
    isLoadingUser,
    isLoadingRoutes,
    isSaving,
    isSavingCandidate,
    isSavingDepot,
    isSavingEvaluation,
    isCalculatingEvaluation,
    isSavingInfrastructure,
    isSavingMapLocation,
    isSavingPto,
    isSavingWaypoints,
    isUpdatingChargingEvent,
    isUpdatingOperationFactors,
    isUpdatingVehicleConfiguration,
    isValidatingChargingEvent,
    isValidatingInfraPerformanceStep,
  ]);

export default useLoadingText;
